import { instanceToPlain, plainToInstance } from 'class-transformer';

export class IExploreSection {
  type: 'hero' | 'prompts' | 'centered' | 'foryou' | 'header';
  title: string;
  subtitle: string;
  height?: 'auto' | number;
}

export class ExploreSection extends IExploreSection {
  static fromObject(
    object: any,
    excludeExtraneousValues = false
  ): ExploreSection {
    switch (object?.type) {
      case 'hero':
        return ExploreHeroSection.fromObject(object, excludeExtraneousValues);
      case 'prompts':
        return ExplorePromptsSection.fromObject(
          object,
          excludeExtraneousValues
        );
      case 'centered':
        return ExploreCenteredSection.fromObject(
          object,
          excludeExtraneousValues
        );
      case 'foryou':
        return ExploreForYouSection.fromObject(object, excludeExtraneousValues);
      case 'header':
        return ExploreHeaderSection.fromObject(object, excludeExtraneousValues);
      default:
        return null;
    }
  }
}

export class ExploreHeroSection extends ExploreSection {
  type: 'hero' = 'hero';

  static new() {
    return plainToInstance(ExploreHeroSection, { type: 'hero' });
  }

  static fromObject(object: any, excludeExtraneousValues = false) {
    return plainToInstance(
      ExploreHeroSection,
      {
        type: 'hero',
        ...object
      },
      { excludeExtraneousValues }
    );
  }

  toObject() {
    return instanceToPlain(this) as ExploreHeroSection;
  }
}

export class ExplorePromptsSection extends ExploreSection {
  type: 'prompts' = 'prompts';
  prompts: string[];

  static new() {
    return plainToInstance(ExplorePromptsSection, { type: 'prompts' });
  }

  static fromObject(object: any, excludeExtraneousValues = false) {
    return plainToInstance(
      ExplorePromptsSection,
      {
        type: 'prompts',
        ...object
      },
      { excludeExtraneousValues }
    );
  }

  toObject() {
    return instanceToPlain(this) as ExplorePromptsSection;
  }
}

export class ExploreCenteredSection extends ExploreSection {
  type: 'centered' = 'centered';

  static new() {
    return plainToInstance(ExploreCenteredSection, { type: 'centered' });
  }

  static fromObject(object: any, excludeExtraneousValues = false) {
    return plainToInstance(
      ExploreCenteredSection,
      {
        type: 'centered',
        ...object
      },
      { excludeExtraneousValues }
    );
  }

  toObject() {
    return instanceToPlain(this) as ExploreCenteredSection;
  }
}

export class ExploreForYouSection extends ExploreSection {
  type: 'foryou' = 'foryou';

  static new() {
    return plainToInstance(ExploreForYouSection, { type: 'foryou' });
  }

  static fromObject(object: any, excludeExtraneousValues = false) {
    return plainToInstance(
      ExploreForYouSection,
      {
        type: 'foryou',
        ...object
      },
      { excludeExtraneousValues }
    );
  }

  toObject() {
    return instanceToPlain(this) as ExploreForYouSection;
  }
}

export class ExploreHeaderSection extends ExploreSection {
  type: 'header' = 'header';

  static new() {
    return plainToInstance(ExploreHeaderSection, { type: 'header' });
  }

  static fromObject(object: any, excludeExtraneousValues = false) {
    return plainToInstance(
      ExploreHeaderSection,
      {
        type: 'header',
        ...object
      },
      { excludeExtraneousValues }
    );
  }

  toObject() {
    return instanceToPlain(this) as ExploreHeaderSection;
  }
}

export function isHeroSection(x: ExploreSection): x is ExploreHeroSection {
  return x?.type === 'hero';
}

export function isPromptsSection(
  x: ExploreSection
): x is ExplorePromptsSection {
  return x?.type === 'prompts';
}

export function isCenteredSection(
  x: ExploreSection
): x is ExploreCenteredSection {
  return x?.type === 'centered';
}

export function isForYouSection(x: ExploreSection): x is ExploreForYouSection {
  return x?.type === 'foryou';
}

export function isHeaderSection(x: ExploreSection): x is ExploreHeaderSection {
  return x?.type === 'header';
}
