import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';

// Libs
import {
  ENDPOINTS,
  ApiSurfaces,
  StartConversationDto,
  StartConversationResponseDto,
  ReplyToConversationResponse,
  ReplyToConversationDto,
  MessageDto,
  MessageResponse,
  PaginatedQueryFilters,
  IConversationResults,
  PaginatedMessageFilters,
  IConversationMessagesResults,
  RateAIConversationMessageDTO,
  IConversationMessage,
  ConversationMessage
} from 'models';
import { ErrorService, MessageService, MessageType } from 'uikit';
import { IAiService } from './ai.service.interface';
import { ApiService } from '../api';

@Injectable({
  providedIn: 'root'
})
export class AiService implements IAiService {
  constructor(
    private _api: ApiService,
    private _error: ErrorService,
    private _message: MessageService
  ) {}

  async startConversation(
    data: StartConversationDto
  ): Promise<StartConversationResponseDto> {
    try {
      const ret = await this._api.post<StartConversationResponseDto>(
        ApiSurfaces.API,
        ENDPOINTS.ai.conversation,
        data
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async replyToConversation(
    conversationId: string,
    data: ReplyToConversationDto
  ): Promise<ReplyToConversationResponse> {
    try {
      const ret = await this._api.post<ReplyToConversationResponse>(
        ApiSurfaces.API,
        `${ENDPOINTS.ai.conversation}/${conversationId}/reply`,
        data
      );
      return ret ? plainToClass(ReplyToConversationResponse, ret) : null;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async toggleMessagePrivacy(
    messageId: string,
    isPublic: boolean
  ): Promise<ConversationMessage> {
    try {
      const ret = await this._api.post<IConversationMessage>(
        ApiSurfaces.API,
        `ai/message/${messageId}/privacy`,
        { public: isPublic }
      );
      return ret ? ConversationMessage.fromObject(ret) : null;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async shortenMessage(data: MessageDto): Promise<MessageResponse> {
    try {
      const ret = await this._api.post<MessageResponse>(
        ApiSurfaces.API,
        `${ENDPOINTS.ai.shortener}`,
        data
      );
      return ret ? plainToClass(MessageResponse, ret) : null;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async rateMessage(
    conversationId: string,
    messageId: string,
    data: RateAIConversationMessageDTO,
    showConfirmation = true
  ): Promise<boolean> {
    try {
      const ret = await this._api.post<boolean>(
        ApiSurfaces.API,
        `${ENDPOINTS.ai.conversation}/${conversationId}/message/${messageId}/rate_message`,
        data
      );
      if (showConfirmation) {
        this._message.show({
          text: ret
            ? 'Thanks for your feedback!'
            : 'There was an error. Please try again.',
          type: ret ? MessageType.SUCCESS : MessageType.ERROR
        });
      }
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  getConversations = async (
    input: PaginatedQueryFilters
  ): Promise<IConversationResults> => {
    try {
      const ret: IConversationResults = await this._api.get(
        ApiSurfaces.API,
        `${ENDPOINTS.ai.conversation}`,
        input
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  };

  getConversationMessagesById = async (
    input: PaginatedMessageFilters
  ): Promise<IConversationMessagesResults> => {
    try {
      return await this._api.get(
        ApiSurfaces.API,
        `${ENDPOINTS.ai.conversation}/${input.id}/messages`,
        {
          ...(input.after && { after: input.after }),
          ...(input.limit && { limit: input.limit }),
          ...(input.offset && { offset: input.offset })
        }
      );
    } catch (e) {
      this._error.displayError(e);
    }
  };

  getConversationById = async ({
    id
  }: {
    id: string;
  }): Promise<IConversationMessagesResults> => {
    try {
      return await this._api.get(
        ApiSurfaces.API,
        `${ENDPOINTS.ai.conversation}/${id}`
      );
    } catch (e) {
      this._error.displayError(e);
    }
  };

  async trackGoogleUserConsent(): Promise<boolean> {
    try {
      const ret = await this._api.post<boolean>(
        ApiSurfaces.API,
        `${ENDPOINTS.organization.googleUserConsent}`
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }
}
