<div *ngIf="message" class="message-bubble">
  <div
    class="message"
    [class.outbound]="message.isOutbound"
    [class.optimistic]="message.isOptimistic"
    [class.full-width]="!showMetadata"
    [innerHTML]="message.displayHtml"
  ></div>

  <div
    *ngIf="showMetadata"
    class="message-timestamp ml-3"
    hoverDirective
    [listener]="actionsView.menuStateChanged"
  >
    <app-conversation-message-actions-view
      [message]="message"
      (onRateMessageClick)="handleRateMessageClick()"
      (onExplainAnswerClick)="handleExplainAnswerClick()"
      (onShareClick)="handleShareClick()"
      #actionsView
    ></app-conversation-message-actions-view>
  </div>
</div>
