<div class="flex gap-4">
  <div class="flex-1">
    <div class="h-80 flex flex-col">
      <p class="font-bold">Visible</p>

      <div
        cdkDropList
        #visibleList="cdkDropList"
        [cdkDropListData]="columns"
        [cdkDropListConnectedTo]="[hiddenList]"
        class="min-h-[60px] rounded-sm overflow-x-hidden overflow-y-auto block border border-dashed border-neutral-200 flex-auto"
        (cdkDropListDropped)="handleDropColumn($event)"
      >
        <div
          class="item flex flex-row cursor-move justify-between items-center box-border m-2 h-9 px-3 bg-neutral-200 rounded-sm"
          *ngFor="let item of columns; let i = index"
          cdkDrag
        >
          {{ getNameForColumn(item) }}
          <span
            nz-icon
            nzType="feather/minus-circle"
            class="cursor-pointer"
            (click)="handleDeleteColumn(item, i)"
          ></span>
          <div
            class="item-placeholder-transition h-9 m-2 bg-mustard-200 rounded-sm"
            *cdkDragPlaceholder
          ></div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-1">
    <div class="h-80 flex flex-col">
      <p class="font-bold">Hidden</p>
      <div
        cdkDropList
        #hiddenList="cdkDropList"
        [cdkDropListData]="hiddenColumns"
        [cdkDropListConnectedTo]="[visibleList]"
        class="min-h-[60px] rounded-sm overflow-x-hidden overflow-y-auto block border border-dashed border-neutral-200 flex-auto"
        (cdkDropListDropped)="handleDropColumn($event)"
      >
        <div
          class="item flex flex-row cursor-move justify-between items-center box-border m-2 h-9 px-3 bg-neutral-200 rounded-sm"
          *ngFor="let item of hiddenColumns; let i = index"
          cdkDrag
        >
          {{ getNameForColumn(item) }}
          <span
            nz-icon
            nzType="feather/plus-circle"
            class="cursor-pointer"
            (click)="handleAddColumn(item, i)"
          ></span>
          <div
            class="item-placeholder-transition h-9 m-2 bg-mustard-200 rounded-sm"
            *cdkDragPlaceholder
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
