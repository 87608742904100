import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// 3rd party
import { QuillModules } from 'ngx-quill';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Subject,
  switchMap,
  takeWhile
} from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';

// Libs
import {
  BaseComponent,
  MockContentManagementService,
  MockContentService,
  MockDeviceService,
  MockFileUploadService,
  MockSearchService
} from 'uikit';
import {
  Content,
  ContentRegisterable,
  ContentSearchResults,
  IContentSearchResultNode,
  SEARCH_DEBOUNCE_TIME,
  SendContentVariableTypeEnum,
  SendUserVariableTypeEnum,
  SendVariable,
  SendVariableDisplayTextEnum,
  uuidv4,
  VARIABLES_KB_URL
} from 'models';
import {
  convertDeltaOpsToMustachePlainText,
  convertNewLinesToHtml,
  convertMustacheToPills,
  convertPillsToMustache,
  createCaret
} from './util';

@Component({
  selector: 'norby-rich-text-editor-view',
  templateUrl: './norby-rich-text-editor-view.component.html',
  styleUrls: [
    '../../../../../../../node_modules/quill/dist/quill.core.css',
    '../../../../../../../node_modules/quill/dist/quill.snow.css',
    './norby-rich-text-editor-view.component.less'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyRichTextEditorViewComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NorbyRichTextEditorViewComponent
  extends BaseComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() placeholder: string;
  @Input() isBorderless = false;
  @Input() isDisabled = false;
  @Input() useVariables?: boolean = false;
  @Input() helperText: string;
  @Input() label?: string;
  @Input() isRequired?: boolean = false;
  @Input() isSingleLine: boolean = false;
  @Input() hasToolbar: boolean = true;
  @Input() hasImageSelection: boolean = true;
  @Input() initWithFocus: boolean = false;
  @Input() isSendField: boolean = false;

  @ViewChild('fileRef') fileRef: ElementRef;
  @ViewChild('imageToolbarIcon') imageToolbarIcon: ElementRef;

  val: string;
  quillEditor;
  quillModules: QuillModules;
  hasFocus = false;
  isUploading = false;
  contentVariableOptions: ContentSearchResults[];
  contentVariableDefaultOptions: ContentSearchResults[];
  editingVariable: SendVariable;
  uuid: string;
  selectorPosition: DOMRect;
  isMobile: boolean = false;
  globalPositionLeft: boolean = false;
  shouldDisplayDefaultOptions: boolean = true;
  isSearchingContentVariable: boolean = false;
  readonly HELPER_URL = VARIABLES_KB_URL;

  // Updated when the selector animation finished
  isVariableSelectorVisible: boolean = false;

  // Updated when variable selector should be opened or closed
  isVariableSelectorVisibilityUpdated: boolean = false;

  private _closingSelector$ = new Subject<boolean>();
  private _contentVariableSearch$ = new Subject<string>();
  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;
  private _clickListeners: { (): void }[] = [];
  private _index: number;
  private _lastEmittedValue: string;
  private _isOpeningSelector = false;
  private _searchQuery: string = '';
  private _toolbarListener: { (): void };

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    @Inject(DOCUMENT) private _document: Document,
    private _upload: MockFileUploadService,
    private _cdr: ChangeDetectorRef,
    private _search: MockSearchService,
    private _r2: Renderer2,
    private _device: MockDeviceService,
    private _content: MockContentService,
    private _contentManagement: MockContentManagementService,
    private _modal: NzModalService
  ) {
    super();

    // Quill is not safe for SSR
    if (isPlatformBrowser(this._platform)) {
      Promise.all([import('quill-image-resizor'), import('quill')]).then(
        ([ImageResizor, Quill]) => {
          Quill?.default?.register(
            'modules/imageResizor',
            ImageResizor?.default
          );
          const block = Quill?.default?.import('blots/block') as any;
          if (this.isSingleLine) {
            block.tagName = 'DIV';
            Quill?.default.register('blots/block', block, true);
          }

          const Embed = Quill?.default?.import('blots/embed') as any;

          Quill?.default?.register(
            class VariablePill extends Embed {
              static blotName = 'variablePill';
              static tagName = 'span';
              static className = 'variable-pill';

              static create(data) {
                const node = super.create();
                node.setAttribute('data-type', data.type);
                if (data.defaultValue) {
                  node.setAttribute('data-value', data.defaultValue);
                }
                if (data.contentId) {
                  node.setAttribute('data-content-id', data.contentId);
                }
                node.innerHTML = SendVariableDisplayTextEnum[data.type];

                const userDefaultValue = document.createElement('span');
                userDefaultValue.innerHTML = data.defaultValue
                  ? ` (${data.defaultValue})`
                  : ' (no fallback)';
                userDefaultValue.setAttribute(
                  'class',
                  data.defaultValue
                    ? 'has-default-value'
                    : 'missing-default-value'
                );

                const contentMissingDisplay = document.createElement('span');
                contentMissingDisplay.innerHTML = ' (no content set)';
                contentMissingDisplay.setAttribute(
                  'class',
                  'missing-default-value'
                );

                if (!!SendUserVariableTypeEnum[data.type]) {
                  node.appendChild(userDefaultValue);
                }
                if (
                  !!SendContentVariableTypeEnum[data.type] &&
                  !data.contentId
                ) {
                  node.appendChild(contentMissingDisplay);
                }
                node.appendChild(createCaret());

                return node;
              }

              static value(domNode) {
                return {
                  type: domNode.getAttribute('data-type'),
                  defaultValue: domNode.getAttribute('data-value'),
                  contentId: domNode.getAttribute('data-content-id')
                };
              }
            }
          );
          const modules = {
            imageResizor: {}
          };

          this.quillModules = !this.isSingleLine
            ? modules
            : {
                ...modules,
                keyboard: {
                  bindings: {
                    tab: false,
                    handleEnter: {
                      key: 13,
                      handler: () => {}
                    }
                  }
                }
              };
          this._cdr.detectChanges();
        }
      );
    }
  }

  ngOnInit(): void {
    if (this.useVariables) {
      this._initContentSearch();
    }
    this.uuid = uuidv4();
    this._device.isMobile$
      .pipe(this.takeUntilDestroy)
      .subscribe((isMobile) => (this.isMobile = isMobile));

    this._closingSelector$
      .pipe(distinctUntilChanged(), this.takeUntilDestroy)
      .subscribe((isClosing) => {
        if (!isClosing && !this._isOpeningSelector) {
          this.editingVariable = null;
          this.isVariableSelectorVisible = false;
          this.isVariableSelectorVisibilityUpdated = false;
          this._resetClickListeners();
          this._cdr.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._clickListeners.forEach((listener) => {
      listener();
    });
    if (this._toolbarListener) {
      this._toolbarListener();
    }
  }

  get shouldRenderEditor(): boolean {
    return isPlatformBrowser(this._platform) && !!this.quillModules;
  }

  get isHtmlDisabled(): boolean {
    return !this.hasToolbar && !this.isSingleLine;
  }

  get hasMaxHeight(): boolean {
    return this.isBorderless && !this.isSendField;
  }

  handleVariableHelp(): void {
    this._modal.create({
      nzTitle: 'Using variables',
      nzContent: `<p>Type '@' to insert a variable anywhere in your message.</p>
      <p>You can use variables to easily personalize and customize your message. For instance, you can use a variable to address users by their name or dynamically include the URL for an event or signup page.</p>
      <p>For more information on using variables in your messages, see the <a href="${this.HELPER_URL}" target="_blank" class="underline">Knowledge Base</a>.</p>`,
      nzFooter: null,
      nzCloseIcon: 'feather/x'
    });
  }

  private _initContentSearch() {
    combineLatest(this._getSuggestedContentQueries())
      .pipe(this.takeUntilDestroy)
      .subscribe(([events, signups]) => {
        const suggestedEvents = (events as ContentRegisterable[]) || [];
        const suggestedSignups = (signups as ContentRegisterable[]) || [];
        const suggestedContent = suggestedEvents.concat(suggestedSignups);
        this.contentVariableDefaultOptions = suggestedContent.map((event) => {
          return {
            content: event,
            highlightedMetadata: null,
            highlightedTitle: null
          };
        });
        this._cdr.detectChanges();
      });

    this._contentVariableSearch$
      .pipe(
        debounceTime(SEARCH_DEBOUNCE_TIME),
        switchMap((query) => {
          this._searchQuery = query;
          this.isSearchingContentVariable = true;
          this._cdr.detectChanges();

          return this._search.searchContent({
            query,
            offset: 0,
            excludeContent: ['link']
          });
        }),
        this.takeUntilDestroy
      )
      .subscribe((rawResults) => {
        this.contentVariableOptions = rawResults?.edges?.map((edge) => {
          const node = edge?.node as IContentSearchResultNode;
          const content = Content.fromObject(node);
          const highlightedTitle = node?._highlights?.title?.[0] ?? '';

          return {
            content,
            highlightedTitle,
            highlightedMetadata: ''
          };
        });
        this.isSearchingContentVariable = false;
        this.shouldDisplayDefaultOptions = !(
          this.contentVariableOptions?.length === 0 &&
          this._searchQuery?.length > 0
        );

        this._cdr.detectChanges();
      });
  }

  private _getSuggestedContentQueries() {
    return [
      this._content
        .getEventsForCurrentSlug$({
          orderBy: 'modifiedAt',
          published: true,
          limit: 3,
          sort: 'desc'
        })
        .pipe(
          filter((summary) => !!summary),
          map((summary) => summary.items)
        ),
      this._contentManagement.getDropsForCurrentSlug$({
        published: true,
        limit: 3
      })
    ];
  }

  openSystemChooseFileDialog() {
    this.fileRef.nativeElement.click();
  }

  private _resetClickListeners() {
    this._clickListeners?.forEach((listener) => {
      listener();
    });

    const variables = this._document
      ?.getElementById(this.uuid)
      ?.getElementsByClassName('variable-pill');
    this._clickListeners = [];

    for (let i = 0; i < variables?.length; i++) {
      this._clickListeners.push(
        this._r2.listen(variables[i], 'click', () => {
          this._updateVariableSelectorPosition();
          if (this.isVariableSelectorVisible) {
            this._closingSelector$
              .pipe(takeWhile((isClosing) => !isClosing, true))
              .subscribe((isClosing) => {
                if (!isClosing && !this._isOpeningSelector) {
                  this.editingVariable = {
                    type: variables[i].getAttribute('data-type') as
                      | SendContentVariableTypeEnum
                      | SendUserVariableTypeEnum,
                    defaultValue: variables[i].getAttribute('data-value'),
                    contentId: variables[i].getAttribute('data-content-id')
                  };
                  this.isVariableSelectorVisible = true;
                  this.isVariableSelectorVisibilityUpdated = true;
                  this._cdr.detectChanges();
                }
              });
          } else {
            this.editingVariable = {
              type: variables[i].getAttribute('data-type') as
                | SendContentVariableTypeEnum
                | SendUserVariableTypeEnum,
              defaultValue: variables[i].getAttribute('data-value'),
              contentId: variables[i].getAttribute('data-content-id')
            };
            this.isVariableSelectorVisible = true;
            this.isVariableSelectorVisibilityUpdated = true;
            this._cdr.detectChanges();
          }
        })
      );
    }
  }

  private _updateVariableSelectorPosition() {
    const windowWidth = this._document.defaultView.innerWidth;

    const selection = this._document.defaultView?.getSelection();

    if (selection?.rangeCount > 0) {
      this.selectorPosition = selection?.getRangeAt(0)?.getBoundingClientRect();
      this.globalPositionLeft = windowWidth * 0.69 < this.selectorPosition.x;
    }
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  registerOnChange(fn: any) {
    this._onChanged = (val) => {
      this._lastEmittedValue = val;
      fn(val);
    };
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  writeValue(value: string) {
    if (value === this._lastEmittedValue) {
      return;
    }

    const lineBreaksConverted = this.isHtmlDisabled
      ? convertNewLinesToHtml(value)
      : value;
    const newVal = this.useVariables
      ? convertMustacheToPills(lineBreaksConverted)
      : lineBreaksConverted;

    if (newVal !== this.val) {
      this._lastEmittedValue = value;
      this.val = newVal;
      if (this._clickListeners.length > 0) {
        setTimeout(() => {
          this._resetClickListeners();
        });
      }
      this._cdr.detectChanges();
    }
  }

  handleEditorCreated(editorInstance) {
    this._resetClickListeners();

    if (this.imageToolbarIcon) {
      this._toolbarListener = this._r2.listen(
        this.imageToolbarIcon.nativeElement,
        'mousedown',
        (e) => {
          e.preventDefault();
        }
      );
    }

    this.quillEditor = editorInstance;
    if (this.isDisabled) {
      this.quillEditor.disable(true);
    } else if (this.initWithFocus) {
      this.hasFocus = true;
      this.quillEditor.setSelection(this.quillEditor.getLength(), 0);
    }
  }

  async handleFileSelected(event: any) {
    const file = event?.target?.files?.[0];
    if (!file) {
      return;
    }

    this.isUploading = true;
    this._cdr.detectChanges();
    const imageUrl = await this._upload.uploadFile(file);
    this.isUploading = false;
    this._cdr.detectChanges();

    if (imageUrl?.length && this.quillEditor) {
      const img = `<img style="max-width:100%" src="${imageUrl}"></img>`;
      const range = this.quillEditor.getSelection();
      this.quillEditor.clipboard.dangerouslyPasteHTML(
        range?.index || 0,
        img,
        'user'
      );
    }
  }

  handleOnContentChanged(editorContent) {
    // No need to detect changes here, we're just letting
    // the form know about changes in the quill editor
    let currentChar;
    const range = this.quillEditor?.getSelection();
    if (range) {
      let pastedLength = 0;
      let index = editorContent?.delta?.ops?.length ?? 1;
      while (!pastedLength && index--) {
        const op = editorContent.delta.ops[index];
        if (op.insert && op.insert.length > 1) {
          pastedLength = op.insert.length;
        }
      }

      if (pastedLength === 2 && range.index - this._index === 1) {
        currentChar = this.quillEditor?.getText(range.index - 1, 1);
      } else {
        currentChar = this.quillEditor?.getText(
          range.index + pastedLength - 1,
          1
        );
      }
      this._index = range.index;
    }
    this._updateVariableSelectorPosition();

    this.isVariableSelectorVisibilityUpdated = currentChar === '@';
    const updatedContents = this.quillEditor?.getContents()?.ops ?? [];
    const updatedHtmlVal = editorContent?.html ?? '';

    if (updatedHtmlVal !== this.val) {
      this.val = updatedHtmlVal;

      const newVal = !this.hasToolbar
        ? convertDeltaOpsToMustachePlainText(updatedContents)
        : this.useVariables
          ? convertPillsToMustache(updatedHtmlVal)
          : updatedHtmlVal;

      if (this.hasFocus) {
        this._onChanged(newVal);
        this._markAsTouched();
      }
    }
  }

  handleOnSelectionChanged(selection) {
    if (selection.range) {
      const range = this.quillEditor?.getSelection(true);
      this._updateVariableSelectorPosition();

      this.isVariableSelectorVisibilityUpdated =
        this.quillEditor?.getText(range.index - 1, 1) === '@';
    }
    if (selection.range === null && selection.oldRange !== null) {
      this.hasFocus = false;
      this._cdr.detectChanges();
    } else if (selection.range !== null && selection.oldRange === null) {
      this.hasFocus = true;
      this._cdr.detectChanges();
    }
  }

  //pass in isSendField to insertEmbed
  handleAddUserVariable(event: SendVariable) {
    const range = this.quillEditor.getSelection(true);
    const charAtCursor = this.quillEditor.getText(range.index - 1, 1);
    if (charAtCursor === '@') {
      this.quillEditor.deleteText(range.index - 1, 1, 'silent');
    }

    this.quillEditor.insertEmbed(
      range.index - 1,
      'variablePill',
      {
        type: event.type,
        defaultValue: event.defaultValue
      },
      'user'
    );

    this._resetClickListeners();
    this.quillEditor.setSelection(range.index);
    this._cdr.detectChanges();
  }

  handleEditUserVariable(event: SendVariable) {
    const range = this.quillEditor.getSelection(true);
    this.quillEditor.deleteText(range.index - 1, 1, 'silent');

    this.quillEditor.insertEmbed(
      range.index - 1,
      'variablePill',
      {
        type: event.type,
        defaultValue: event.defaultValue
      },
      'user'
    );

    this._resetClickListeners();
    this.quillEditor.setSelection(range.index);
    this._cdr.detectChanges();
  }

  handleAddContentVariable(event: SendVariable) {
    const range = this.quillEditor.getSelection(true);

    const charAtCursor = this.quillEditor.getText(range.index - 1, 1);
    if (charAtCursor === '@') {
      this.quillEditor.deleteText(range.index - 1, 1, 'silent');
    }

    this.quillEditor.insertEmbed(
      range.index - 1,
      'variablePill',
      {
        type: event.type,
        contentId: event.contentId
      },
      'user'
    );

    this._resetClickListeners();
    this.quillEditor.setSelection(range.index);
    this._cdr.detectChanges();
  }

  handleEditContentVariable(event: SendVariable) {
    const range = this.quillEditor.getSelection(true);
    this.quillEditor.deleteText(range.index - 1, 1, 'silent');

    this.quillEditor.insertEmbed(
      range.index - 1,
      'variablePill',
      {
        type: event.type,
        contentId: event.contentId
      },
      'user'
    );

    this._resetClickListeners();
    this.quillEditor.setSelection(range.index);
    this._cdr.detectChanges();
  }

  handleContentVariableSearchInput(query: string) {
    this._contentVariableSearch$.next(query);
  }

  handleSlideFinished(event) {
    if (!event.toState) {
      this._isOpeningSelector = false;
      this.isVariableSelectorVisible = true;
    } else {
      this._closingSelector$.next(false);
    }
  }

  handleSlideStarted(event) {
    if (!event.toState) {
      this._isOpeningSelector = true;
    } else {
      this._closingSelector$.next(true);
    }
  }
}
