import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';

import { IIntegrationsService } from './integrations.service.interface';
import {
  IntegrationType,
  ENDPOINTS,
  ApiSurfaces,
  IAccountIntegration,
  IAccountIntegrationJob,
  IAccountIntegrationMetadataType,
  IAccountIntegrationResponse,
  IUpdateIntegrationFeaturesDTO
} from 'models';
import { ErrorService, MessageService, MessageType } from 'uikit';
import { ApiService } from '../api';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService implements IIntegrationsService {
  constructor(
    private _api: ApiService,
    private _error: ErrorService,
    private _message: MessageService
  ) {}

  getAccountIntegrations = async (
    isLinked = true
  ): Promise<IAccountIntegration[]> => {
    const integrations: IAccountIntegrationResponse = await this._api.get<any>(
      ApiSurfaces.AUTH,
      `${ENDPOINTS.integration.accountIntegrations}?linked=${isLinked}`
    );

    return integrations?.integrations?.map((integration) =>
      IAccountIntegration.fromObject(integration)
    );
  };

  getAccountIntegrationsForType = async ({
    integrationType,
    isLinked = true
  }: {
    integrationType: IAccountIntegrationMetadataType;
    isLinked: boolean;
  }): Promise<IAccountIntegration[]> => {
    const integrations: IAccountIntegrationResponse = await this._api.get<any>(
      ApiSurfaces.AUTH,
      `${ENDPOINTS.integration.accountIntegrationsByType}/${integrationType}?linked=${isLinked}`
    );

    return integrations?.integrations?.map((integration) =>
      IAccountIntegration.fromObject(integration)
    );
  };

  async chooseGoogleAnalyticsProperty(
    accountId: string,
    property: string
  ): Promise<boolean> {
    if (!accountId?.length || !property?.length) {
      return false;
    }

    try {
      await this._api.post(
        ApiSurfaces.AUTH,
        `integration/auth/google/${accountId}/select_properties`,
        {
          properties: [property]
        }
      );
      return true;
    } catch (e) {
      this._error.displayError(e);
      return false;
    }
  }

  async chooseEventbriteOrganization(
    accountId: string,
    organizationId: string
  ): Promise<boolean> {
    if (!accountId?.length || !organizationId?.length) {
      return false;
    }

    try {
      await this._api.post(
        ApiSurfaces.AUTH,
        `integration/auth/eventbrite/${accountId}/select_organizations`,
        {
          organizationIds: [organizationId]
        }
      );
      return true;
    } catch (e) {
      this._error.displayError(e);
      return false;
    }
  }

  async chooseMetaAdAccount(
    accountId: string,
    adAccountId: string
  ): Promise<boolean> {
    if (!accountId?.length || !adAccountId?.length) {
      return false;
    }

    try {
      await this._api.post(
        ApiSurfaces.AUTH,
        `integration/auth/meta/${accountId}/select_ad_accounts`,
        {
          adAccountIds: [adAccountId]
        }
      );
      return true;
    } catch (e) {
      this._error.displayError(e);
      return false;
    }
  }

  async chooseTikTokAdsAccount(
    accountId: string,
    adAccountId: string
  ): Promise<boolean> {
    if (!accountId?.length || !adAccountId?.length) {
      return false;
    }

    try {
      await this._api.post(
        ApiSurfaces.AUTH,
        `integration/auth/tikTokAds/${accountId}/select_ad_accounts`,
        {
          adAccountIds: [adAccountId]
        }
      );
      return true;
    } catch (e) {
      this._error.displayError(e);
      return false;
    }
  }

  async chooseGoogleAdAccount(
    accountId: string,
    adAccountId: string
  ): Promise<boolean> {
    if (!accountId?.length || !adAccountId?.length) {
      return false;
    }

    try {
      await this._api.post(
        ApiSurfaces.AUTH,
        `integration/auth/googleAds/${accountId}/select_ad_accounts`,
        {
          ad_accounts: [adAccountId]
        }
      );
      return true;
    } catch (e) {
      this._error.displayError(e);
      return false;
    }
  }

  async setPermittedSlackUsers(
    accountId: string,
    selectedEmails: string[]
  ): Promise<boolean> {
    if (!accountId?.length || !selectedEmails?.length) {
      return false;
    }

    try {
      await this._api.post(
        ApiSurfaces.AUTH,
        `integration/auth/slack_norby_chat/${accountId}/set_permitted_users`,
        {
          permittedSlackUserEmails: selectedEmails
        }
      );
      return true;
    } catch (e) {
      this._error.displayError(e);
      return false;
    }
  }

  async linkBeehiiv(apiKey: string): Promise<boolean> {
    try {
      await this._api.post(ApiSurfaces.AUTH, 'integration/auth/beehiiv/link', {
        apiKey
      });
      return true;
    } catch (e) {
      this._error.displayError(e);
      return false;
    }
  }

  async linkSkio(apiKey: string): Promise<boolean> {
    try {
      await this._api.post(ApiSurfaces.AUTH, 'integration/auth/skio/link', {
        apiKey
      });
      return true;
    } catch (e) {
      this._error.displayError(e);
      return false;
    }
  }

  async linkShopifyToSlug(slug: string): Promise<boolean> {
    try {
      await this._api.post(
        ApiSurfaces.AUTH,
        ENDPOINTS.integration.linkShopify,
        {
          slug
        }
      );
      this._message.show({
        text: 'Connected to Shopify',
        type: MessageType.SUCCESS
      });
      return true;
    } catch (e) {
      this._error.displayError(e);
      return false;
    }
  }

  async unlinkFacebook(id: string, deleteData = false): Promise<void> {
    try {
      await this._api.delete<any>(
        ApiSurfaces.AUTH,
        `integration/auth/facebook/${id}/unlink?deleteData=${deleteData}`
      );
      this._message.show({
        text: 'Successfully disconnected from Instagram!',
        type: MessageType.SUCCESS
      });
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async unlinkInstagram(id: string): Promise<void> {
    await this._api.delete<any>(
      ApiSurfaces.AUTH,
      `integration/auth/instagram/${id}/unlink`
    );
  }

  async unlinkAccountIntegration(
    integration: IntegrationType,
    id: string
  ): Promise<void> {
    const path =
      integration === 'norbyChatSlackBot' ? 'slack_norby_chat' : integration;
    try {
      await this._api.delete<any>(
        ApiSurfaces.AUTH,
        `integration/auth/${path}/${id}/unlink`
      );
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async patchPermissions(args: {
    accountId: string;
    features: IUpdateIntegrationFeaturesDTO;
  }): Promise<void> {
    const { accountId, features } = args ?? {};
    try {
      await this._api.patch<any>(
        ApiSurfaces.AUTH,
        `/integration/${accountId}/features`,
        features
      );
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async getIntegrationToken(): Promise<string> {
    const token = await this._api.post<any>(
      ApiSurfaces.AUTH,
      ENDPOINTS.auth.integration
    );
    return token?.token ?? null;
  }

  getAccountIntegrationJob = async (
    id: string
  ): Promise<IAccountIntegrationJob> => {
    try {
      const integrationJob: IAccountIntegrationResponse =
        await this._api.get<any>(
          ApiSurfaces.AUTH,
          `${ENDPOINTS.integration.accountIntegrationJob}/${id}/status`
        );

      return plainToClass(IAccountIntegrationJob, integrationJob);
    } catch (e) {
      this._error.displayError(e);
    }
  };
}
