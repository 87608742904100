<div
  class="flex items-center gap-3 text-neutral-500"
  *ngIf="message.isInbound && !useDropdown"
>
  <button
    *ngFor="let item of MENU_ITEMS"
    (click)="item.handler()"
    class="font-medium hover:text-neutral-800 disabled:text-neutral-500 text-lg ant-btn-animation"
    nz-tooltip
    [nzTooltipTitle]="item.label"
  >
    <i nz-icon [nzType]="item.icon"></i>
  </button>
  <div
    class="text-xs cursor-default"
    nz-tooltip
    [nzTooltipTitle]="timestampAbsolute"
  >
    {{ timestampRelative }}
  </div>
</div>

<div
  class="flex items-center gap-3 text-neutral-500"
  *ngIf="message.isInbound && useDropdown"
>
  <div class="text-xs cursor-default">
    {{ timestampAbsolute }}
  </div>
  <button
    nz-dropdown
    aria-label="More"
    class="font-medium hover:text-neutral-800 disabled:text-neutral-500 text-lg ant-btn-animation"
    [nzDropdownMenu]="menu"
    (nzVisibleChange)="handleVisibleChanged($event)"
    [nzClickHide]="false"
  >
    <i nz-icon nzType="feather/more-horizontal"></i>
  </button>

  <!-- <button
    nz-dropdown
    nz-button
    aria-label="More"
    nzType="text"
    nzSize="small"
    [nzDropdownMenu]="menu"
    (nzVisibleChange)="handleVisibleChanged($event)"
    [nzClickHide]="false"
  >
    <i nz-icon nzType="feather/more-horizontal"></i>
  </button> -->
</div>

<div class="flex items-center" *ngIf="!message.isInbound">
  <div class="text-xs text-neutral-500 cursor-default">
    {{ timestampAbsolute }}
  </div>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li *ngFor="let item of MENU_ITEMS" nz-menu-item (click)="item.handler()">
      <i nz-icon [nzType]="item.icon"></i>
      {{ item.label }}
    </li>
  </ul>
</nz-dropdown-menu>
