import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

// 3rd party
import { combineLatest, filter, firstValueFrom } from 'rxjs';

// Lib
import {
  ENDPOINTS,
  ApiSurfaces,
  API_URL_TOKEN,
  ISearchResults,
  ISearchService
} from 'models';
import { ApiService } from '../api';
import { AuthService } from '../auth';
import { DeviceService } from '../device';

export type SearchToken = { token: string };

@Injectable({
  providedIn: 'root'
})
export class SearchService implements ISearchService {
  private _headers;

  constructor(
    @Inject(API_URL_TOKEN) private _apiUrl: string,
    private _api: ApiService,
    private _auth: AuthService,
    private _device: DeviceService,
    private _http: HttpClient
  ) {
    combineLatest([this._auth.authState$, this._device.currentSlug$])
      .pipe(filter(([user]) => user && !user.isAnonymous))
      .subscribe(() => this._initHeaders());
  }

  private _getApiUrl(uri: string) {
    const base = this._apiUrl;
    const url = new URL(uri, base);
    return url.href;
  }

  private async _getHttpOpts(params?: any) {
    return {
      headers: this._headers,
      ...(params && { params })
    };
  }

  private async _initHeaders() {
    const searchToken = await this._getSearchToken();
    const slug = this._device.currentSlug;
    this._headers = {
      'x-search-token': searchToken?.token,
      'x-slug': slug,
      'x-client-tz': this._device.timezone
    };
  }

  private async _getSearchToken(): Promise<SearchToken> {
    return this._api.post<SearchToken>(ApiSurfaces.AUTH, ENDPOINTS.auth.search);
  }

  // retry wrapper that will attempt to send a request with the existing ES token
  // if it's expired, it will fetch a new token and retry the request
  private async _retry(req: (args?: any) => Promise<ISearchResults>) {
    try {
      return await req();
    } catch (e) {
      // if auth token has expired, reconstruct header with new token and retry request
      if (e.status === 403) {
        this._initHeaders();
        return req();
      } else {
        throw e;
      }
    }
  }

  private async _get<T>(route: string, params?: any) {
    const opts = await this._getHttpOpts(params);
    const url = this._getApiUrl(route);
    return firstValueFrom(this._http.get<T>(url, opts));
  }

  async searchContacts(queryFilters: {
    query: string;
    limit?: number;
    offset?: number;
  }): Promise<ISearchResults> {
    return this._retry(() =>
      this._get(ENDPOINTS.search.contacts, queryFilters)
    );
  }

  async searchContent(queryFilters: {
    query: string;
    limit?: number;
    offset?: number;
    digest?: boolean;
    excludeContent?: string[];
    includeContent?: string[];
  }): Promise<ISearchResults> {
    return this._retry(() => this._get(ENDPOINTS.search.content, queryFilters));
  }

  async searchContactLists(queryFilters: {
    query: string;
    limit?: number;
    offset?: number;
    digest?: boolean;
  }): Promise<ISearchResults> {
    return this._retry(() =>
      this._get(ENDPOINTS.search.contactList, queryFilters)
    );
  }

  async searchSends(queryFilters: {
    query: string;
    limit?: number;
    offset?: number;
    includeSentOnly?: boolean;
  }): Promise<ISearchResults> {
    return this._retry(() =>
      this._get(ENDPOINTS.search.singleSends, queryFilters)
    );
  }

  async searchExampleQuestions(queryFilters: {
    query: string;
    limit?: number;
    offset?: number;
  }): Promise<ISearchResults> {
    return this._retry(() =>
      this._get(ENDPOINTS.search.exampleQuestions, queryFilters)
    );
  }
}
