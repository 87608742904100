import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { SafeHtmlPipeModule } from 'uikit';
import { ConversationMessageView } from '../views/conversation-message-view/conversation-message-view.component';
import { ConversationMessageActionsView } from '../views/conversation-message-view/conversation-message-actions-view.component';
import { HoverDirectiveModule } from './hover-directive.module';

@NgModule({
  declarations: [ConversationMessageView, ConversationMessageActionsView],
  imports: [
    CommonModule,
    SafeHtmlPipeModule,
    NzModalModule,
    NzIconModule,
    NzDropDownModule,
    NzButtonModule,
    NzToolTipModule,
    HoverDirectiveModule
  ],
  exports: [ConversationMessageView, ConversationMessageActionsView]
})
export class ConversationMessageViewModule {}
