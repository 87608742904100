import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { TileButtonViewComponent } from '../views/tile-button-view';

@NgModule({
  declarations: [TileButtonViewComponent],
  imports: [CommonModule, NzIconModule],
  exports: [TileButtonViewComponent]
})
export class TileButtonViewModule {}
