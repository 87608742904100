import {
  Component,
  Inject,
  OnInit,
  Optional,
  TemplateRef
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';

// 3rd party
import { NzModalRef } from 'ng-zorro-antd/modal';

// Libs
import { BaseComponent, ErrorService, ModalComponent } from 'uikit';
import { IS_NORBY_NEXT, ISlug, ROLE_OPTIONS } from 'models';

import {
  SlugManagementService,
  PermissionsService,
  SlugService
} from '../../services';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.less']
})
export class InviteUserComponent extends ModalComponent implements OnInit {
  slug: ISlug;
  formGroup: UntypedFormGroup;
  userIsGlobalAdmin = false;

  // Can be passed in by caller for context-specific additions to the modal
  additionalContent: TemplateRef<any>;

  readonly ROLE_OPTIONS = ROLE_OPTIONS;
  readonly DURATIONS = [
    { label: 'Never', value: 0 },
    { label: '12 hours', value: 720 },
    { label: '1 day', value: 1440 },
    { label: '2 days', value: 2880 }
  ];

  constructor(
    @Optional() @Inject(IS_NORBY_NEXT) private _isNorbyNext: boolean,
    private _formBuilder: UntypedFormBuilder,
    private _slugManagement: SlugManagementService,
    private _permissions: PermissionsService,
    private _slug: SlugService,
    private _error: ErrorService,
    private _dialogRef: NzModalRef<InviteUserComponent>
  ) {
    super();
  }

  get showRoles() {
    return !this._isNorbyNext;
  }

  ngOnInit(): void {
    this._slug
      .getCurrentSlug$()
      .pipe(this.takeUntilDestroy)
      .subscribe((slug) => (this.slug = slug));

    this._permissions
      .userIsGlobalAdmin()
      .then((isAdmin) => (this.userIsGlobalAdmin = isAdmin));

    this._dialogRef.updateConfig({
      nzOnOk: () => this._submit(),
      nzOkDisabled: true
    });

    this._initForm();
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      role: ['editor', this._isRoleValid],
      onboarding: [false],
      expiresInMinutes: [0, [Validators.required]]
    });

    this.formGroup.valueChanges.pipe(this.takeUntilDestroy).subscribe(() =>
      this._dialogRef.updateConfig({
        nzOkDisabled: !this.formGroup.valid
      })
    );
  }

  private _submit() {
    if (this.formGroup.invalid) {
      return;
    }

    this._dialogRef.updateConfig({ nzOkLoading: true });
    const { email, role, onboarding, expiresInMinutes } = this.formGroup.value;

    return this._slugManagement
      .inviteUserToOrganization({
        userIdentifier: email,
        role,
        slug: this.slug?.slug,
        ...(this.userIsGlobalAdmin && { onboarding }),
        ...(this.userIsGlobalAdmin &&
          expiresInMinutes > 0 && { expiresInMinutes })
      })
      .then(() => this._dialogRef.close())
      .catch((e) => this._error.displayError(e))
      .finally(() => this._dialogRef.updateConfig({ nzOkLoading: false }));
  }

  private _isRoleValid(control: AbstractControl): ValidationErrors | null {
    const role = control.value;
    return ['administrator', 'editor', 'contributor'].includes(role)
      ? null
      : { invalidRole: true };
  }
}
